.scroll-nav-cont {
  height: 90px;
}

.scroll-nav {
  z-index: 200;
  background-color: rgba(#FFF, .8);
  li.to-top {
    opacity: 0;
    pointer-events: none;
    transition: all .5s ease;
  }
  &.fixed {
    transition: background-color .5s ease;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    nav {
      margin-bottom: 0;
    }
    li.to-top {
      opacity: 1;
      pointer-events: auto;
    }
    &:hover {
      background-color: rgba(#f5f5f5, .95);
    }
  }
  .fa {
    font-size: 14px;
    &.fa-caret-up {
      margin-left: 3px;
    }
  }
  .nav {
    > li {
      > a {
        padding: 10px 14px;
      }
    }
  }
}

.section-scroll {
  padding: 30px 0;
}

@media (min-width: $screen-md) {
  .section-scroll {
    padding: 100px 0;
    //min-height: 100vh;
  }
}