.navbar-toggle {
  margin-right: 0;
}


.navbar-nav {
  text-transform: uppercase;
  font: {
    family: $font-family-monospace;
    weight: 500;
    size: 12px;
  }
  letter-spacing: .18em;
}

@media (max-width: $screen-xs-max) {
  .navbar-collapse {
    text-align: center;
    box-shadow: none;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }
}

@media (min-width: $screen-sm) {
  .navbar-nav {
    li {
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        pointer-events: none;
        height: 2px;
        bottom: 0;
        left: 50%;
        right: 50%;
        margin: 0 -10px;
        transform: translateY(5px);

        background: #000;
        opacity: 0;
        transition: all .2s ease;
      }
      &:hover, &.active {
        &::after {
          //margin: 0 -10px;
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

}