.row {
  &.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > [class^="col-"],
    > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &.row-pad-5 {
    margin-right: -5px;
    margin-left: -5px;

    > [class^="col-"],
    > [class*=" col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  &.row-pad-10 {
    margin-right: -10px;
    margin-left: -10px;

    > [class^="col-"],
    > [class*=" col-"] {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
