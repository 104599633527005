h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    letter-spacing: .16em;
    //font-weight: normal;
  /*&.chapter {
    color: $brand-color-3;
  }
  &.stroke-r {
    margin-top: 4px;
    &::after {
      content: '';
      display: inline-block;
      //flex: 1;
      width: 60px;
      vertical-align: middle;
      margin: 0 0 6px 15px;
      //margin: 0 .25em;
      border-bottom: 1px solid $brand-color-1;
    }
  }*/
}
h1{
  margin-bottom: 50px;
  letter-spacing: .12em;

  //font-weight: 300;
}

body {
  //font-weight: 300;
}

/*

h1, section.about h2 {
  font-size: 44px;
  font-weight: 900;
  margin-top: 56px;
  margin-bottom: 40px;
  color: $brand-color-3;
  letter-spacing: .16em;
  //text-shadow: 0 15px 15px rgba($brand-color-1, .15);
}
h2.filter{
  margin-top: 0;
  color: rgba(#000,.2);
  font-size: 42px;
  font-family: $font-family-serif;
  font-weight: 300;
}
*/

p {
  //margin: 0 0 ($line-height-computed / 1.68);
}

.sans {
  font-family: $font-family-sans-serif;
}

.serif {
  font-family: $font-family-serif;
}

.fwn {
  font-weight: normal;
}

.hat {
  font-family: $font-family-serif;
  font-size: 20px;
  line-height: 1.8;
  color: #000;
  margin-bottom: 33px;
}

.date{
  font-family: $font-family-monospace;
}


blockquote{
  font-family: $font-family-monospace;
  text-align: center;
  line-height:1.7;
  letter-spacing: .08em;
  //font-weight: 100;
  //text-transform: uppercase;
  //padding: 50px 0;
  padding: 0;
  border: none;
  //margin: 0 0 9px;
  p{
    padding-left: 20px;
    padding-right: 20px;
  }
  cite{
    //color: #e597a8;
    font-weight: normal;
    font-style: normal;
    display: block;
    //font-family: $font-family-base;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: .2em;
    line-height: 1.7;
    margin-top: 42px;
    &::before{
      content: '\2014';
      display: inline-block;
      //font-weight: bold;
      margin-right: 8px;
    }
  }
}

@media (min-width: $screen-sm) {
  /*h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
  }
  h1 {
    font-size: 54px;
    line-height: 1.2;
    margin-top: 77px;
    margin-bottom: 48px;
  }
  h2.filter{
    margin: 30px 0 0 -19px;
  }*/
}

@media (min-width: $screen-md) {
  .text-multi-cols {
    column-count: 2;
    column-gap: 60px;
  }
  /*h1 {
    line-height: 1.3;
    margin-top: 73px;
    margin-bottom: 44px;
  }
  h2.filter{
    margin: 0;
  }
  .no-mt-md {
    margin-top: 0;
  }
  .mt-5-md {
    margin-top: 5px;
  }
  h1, h2, h3, h4, h5, h6 {
    &.stroke-r {
      display: flex;
      &::after {
        flex: 1;
        margin: 0 0 8px 15px;
      }
    }
  }
  .text-col-2{
    column-count: 2;
    column-gap: 60px;
  }*/
}

@media (min-width: $screen-xl) {
  .text-multi-cols {
    column-count: 3;
  }
}