.filter-nav {
  font-family: $font-family-monospace;
  margin: 0 0 30px;
  a {
    display: inline-block;
    & + a {
      margin-left: 10px;
    }
  }
  select {
    //display: inline-block !important;
    display: none;
  }
  div.cs-select {
    //display: inline-block;
    //width: 200px;
    //margin-left: 10px;
    margin-bottom: 10px;
    & + .cs-select {
    }
  }

}

@media (min-width: $screen-sm) {
  .filter-nav {
    margin: 30px 0;

    div.cs-select {
      display: inline-block;
      width: 185px;
      margin-left: 10px;
      margin-bottom: 0;
      & + .cs-select {
      }
    }

  }
}
@media (min-width: $screen-md) {
  .filter-nav {
    div.cs-select {
      width: 230px;
    }
  }
}