$footer-color: #000;
$footer-links: #000;
$footer-bg: #FFF;

footer {
  font: {
    size: 10px;
    weight: normal;
    family: $font-family-monospace;
  }
  color: $footer-color;
  position: relative;
  //background: $footer-bg;
  text-align: center;
  padding: 60px 0 34px;
  margin: 0;
  //box-shadow: 0 -1px 0 red inset;
  a {
    color: $footer-links;
    text-decoration: none !important;
  }
  .logo {
    display: inline-block;
    margin-bottom: 21px;
  }

  div.footer {
    line-height: 2.3;
    margin-bottom: 56px;
  }

  .copyright {
    font: {
      family: $font-family-sans-serif;
      size: 13px;
    }
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
  .by-nec {
    font: {
      //size: 11px;
      family: $font-family-monospace;
    }
    //position: absolute;
    //right: 30px;
    //bottom: 15px;
    span {
      //opacity: 0;
      text-align: right;
      //letter-spacing: -.5em;
      //transition: all .2s ease;
      //margin-right: 32px;
    }
    svg {
      //display: block;
      //position: absolute;
      //top: -2px;
      //right: 0;
      vertical-align: middle;
    }

  }



  nav.social {
    padding-top: 29px;
    a {
      color: $brand-color-1;
      border-color: #f7d7dd;
      &:hover {
        color: #000;
        border-color: #000;

      }
    }
  }
  //box-shadow: 0 3px 3px #eaeaea inset;
  //border-top: 3px solid #dde7f0;

}
html.no-touch {
  footer {
    .by-nec {
      span {
        opacity: 0;
        text-align: right;
        letter-spacing: -.5em;
        transition: all .2s ease;
        //margin-right: 32px;
      }
      &:hover {
        span {
          opacity: 1;
          letter-spacing: 0em;
        }
      }
    }
  }
}
@media (max-width: $screen-xs-max) {
  footer {
    .pull-left, .pull-right {
      float: none !important;
      text-align: center;
    }
  }
}

@media (min-width: $screen-sm) {
  footer {
    padding: 64px 0 40px;
  }
}

@media (min-width: $screen-md) {
  footer {
    text-align: left;
    padding: 73px 0 40px;
    .logo {
      margin-bottom: 0;
    }
    div.footer {
      display: flex;
      margin-right: -($grid-gutter-width);
      margin-bottom: 68px;
      //width: 100%;
      //box-shadow: 0 0 0 1px red inset;
      > div {
        width: 25%;
        //box-shadow: 0 0 0 1px red inset;
        &:not(:first-child) {
          padding-left: 20px;
          padding-top: 13px;
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -20px;
            width: 0;
            height: 100%;
            border-left: 1px solid #f7d7dd;
          }
        }
      }
    }
    nav.social {
      text-align: left;
      padding-top: 5px;
    }
    .copyright {
      line-height: inherit;
    }
  }
  .by {
    text-align: right;
  }
}