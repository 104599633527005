//Colors
$brand-color-1: #001;
$brand-color-2: #001;
$brand-color-3: #001;
$brand-color-4: #001;
$brand-color-5: #001;


.brand-color-1{color: $brand-color-1;}
.brand-color-2{color: $brand-color-2;}
.brand-color-3{color: $brand-color-3;}
.brand-color-4{color: $brand-color-4;}
//$brand-color-4: set_hsb($brand-color-3,$s:5%,$b:97%);
//$brand-color-4: change_color($brand-color-3, $lightness:95%);
//$brand-color-4: adjust_color($brand-color-3, $lightness:28%);
//$brand-color-5: adjust_color($brand-color-3, $lightness:20%, $saturation:-25%);
$brand-primary : $brand-color-1;


$bg-1 : #393b2f;

$hr-border : $brand-color-2;

// GRID
//$grid-columns : 12;
//$grid-gutter-width:20px;
$body-bg:#fff;

// BREAK POINTS
//$screen-sm : 620px;
//$container-sm: 580px;


// NAVBAR
$nav-transition : all .3s ease;

$navbar-default-toggle-border-color : transparent;
$navbar-default-toggle-hover-bg : transparent;
$navbar-default-toggle-icon-bar-bg : #000;

//$navbar-default-border : #000;
$navbar-default-color:#000;
$navbar-default-bg:rgba(#FFF,0);
$navbar-default-link-color:#000;
$navbar-default-link-hover-color:#000;
$navbar-default-link-active-color:#000;
$navbar-default-link-active-bg:transparent;
$navbar-default-link-hover-bg:transparent;
//$navbar-bg : none;
//$navbar-inverse-bg:change_hsb($brand-color-1,$b:25%);
$navbar-inverse-bg:adjust_hsb($brand-color-2,$b:-5%,$s:5%);
$navbar-inverse-link-color : rgba(#FFF,0.8);
$navbar-inverse-link-hover-color: #FFF;
$navbar-inverse-link-hover-bg:transparent;
$navbar-inverse-link-active-color: #FFF;

$nav-bg: rgba(#eee,.95);
$nav-link: #999;
$nav-text:#999;
$nav-hover:#333;
$nav-active:#000;
//$navbar-inverse-link-active-bg:background: linear-gradient(to bottom, lighten($brand-color-2, 20%) 0%, $brand-color-2 100%);

$dropdown-link-color : $brand-color-1;
$dropdown-link-hover-color : #FFF;
$dropdown-link-hover-bg : $brand-color-1;
$dropdown-link-active-bg : $brand-color-2;

$zindex-dropdown : 1500;
//$nav-tabs-active-link-hover-bg : red;

// TYPE
$headings-font-family : "Lato", Helvetica, Arial, sans-serif;
$headings-color : #000;
//$headings-line-height : 1.3;
$headings-line-height : 1.23;
$headings-font-weight : bold;
$font-size-h3 : 22px;
$font-family-serif : "merriweather", Georgia, Times, serif;
//$font-family-base : "Raleway", sans-serif;
$font-family-base : "Lato", Helvetica, Arial, sans-serif;
$font-family-sans-serif : $font-family-base;
$font-family-monospace : 'Roboto Mono', monospace;

$headings-font-family : $font-family-monospace;
//$font-family-base : "Open Sans", sans-serif;
$font-size-base: 16px;


$text-color:#000;
//$line-height-base : 2.45;
$line-height-base : 2.2;
$link-color : $brand-color-1;
$link-hover-color : $brand-color-1;
$link-hover-decoration: underline;
//$font-size-h1:54px;
//$grid-float-breakpoint : 992px;


//$blockquote-border-color:$brand-color-1;
//$link-hover-color : $brand-color-2;

//$line-height-computed : 2;

// FORMS
//$input-border : #dde7f0;
//$input-color : darken($brand-color-5,20%);
$input-border : #e3e3e3;
$input-border-focus : #e597a8;
//$input-bg:transparent;
//$input-color:#FFF;

$input-group-addon-bg : $brand-color-2;


$label-color:#333;


$border-radius-base : 0px;
$border-radius-large : 0px;
$border-radius-small : 0px;

// BUTTONS
.btn{
  //font-family: $headings-font-family;
  //text-transform: uppercase;
  //font-weight: bold;
}
$btn-font-weight:500;
//$btn-default-color : change_hsb($brand-color-3,$b:60%, $s:40%);
//$btn-default-border : change_hsb($brand-color-3,$b:90%, $s:10%);
//$btn-default-bg : change_hsb($brand-color-3,$b:99%,$s:1%);

//$navbar-height:60px;

// PANELS
$panel-default-heading-bg : change_hsb($brand-color-3,$b:98%,$s:2%);
$panel-default-border : change_hsb($brand-color-3,$b:90%,$s:8%);

// TOOLTIP
$tooltip-bg:adjust_hsb($brand-color-1,$b:-20%);

// TABLES
//$table-bg-accent:change_hsb($brand-color-3, $b: 99%, $s: 1%);
//$table-border-color: change_hsb($brand-color-3, $b: 90%, $s: 8%);

$thumbnail-bg:#FFF;

// PAGINATION
$pagination-color:#999;
$pagination-bg: transparent;
$pagination-hover-bg:transparent;
$pagination-border:rgba(#000,0);
$pagination-hover-color:$brand-color-1;
$pagination-hover-border:rgba(#000,0);
$pagination-active-color:$brand-color-1;
$pagination-active-bg:transparent;
$pagination-active-border:rgba(#000,0);
$pagination-disabled-border:rgba(#000,0);
$pagination-disabled-bg:transparent;

//BREADCRUMB
//$breadcrumb-separator: '/';
$breadcrumb-bg:transparent;
$breadcrumb-color:$text-color;
$breadcrumb-padding-horizontal:0;
$breadcrumb-padding-vertical:0;

//$screen-lg:1240px;
//$container-large-desktop:(1180px + $grid-gutter-width);


// ALERTS
$alert-success-bg:#FFF;
$alert-success-border:#d3f0da;
$state-success-text:#35af6d;
$alert-danger-bg:#FFF;
$alert-danger-border:#f7d7dd;
$state-danger-text:$brand-color-1;

// BLOCKQUOTE
$blockquote-font-size:26px;
