section.header {
  .error-404 {
    padding: 80px 0;
    h2 {
      margin: 4px 0 50px;
      padding: 0;
      &::after {
        display: none;
      }
    }
    h6, a {
      color: #FFF;
    }
    a.side-strokes {
      &::before, &::after {
        background-color: #FFF;
      }
    }
  }
}
@media (min-width: $screen-sm) {
  section.header {
    .error-404 {
      padding: 114px 0 78px;
      h2 {
        margin: 20px 0 82px;
      }
    }
  }
}
