.pagination{
  font-size: 21px;
  font-weight: 400;
  a,span{
    line-height: 20px !important;
  }
  .pager-first span:before,
  .pager-prev span:before,
  .pager-next span:before,
  .pager-last span:before{
    font-family: FontAwesome;
    font-weight: normal;
  }
  .pager-first span:before{
    content:"\f049";
  }
  .pager-prev span:before{
    content:"\f104";
  }
  .pager-next span:before{
    content:"\f105";
  }
  .pager-last span:before{
    content:"\f050";
  }
  .disabled > span,
  .disabled > span:hover,
  .disabled > span:focus
  {
    cursor: not-allowed;
    color: #DDD;
  }
}