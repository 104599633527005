.backLink {
  margin: 60px 0;
  a {
    text-decoration: none !important;
    text-transform: uppercase;
    letter-spacing: .18em;
    font: {
      size: 12px;
      family: $font-family-monospace;
      weight: 500;
    }
    display: inline-block;
    border-top: 1px solid #000;
  }
}

/*.btn-zoom {
  position: relative;
  text-align: center;
  //padding: 80px 0 130px;
  perspective: 1000px;
  overflow: hidden;
  height: 300px;
  > * {
    //>.bg,>.bg-grad-01,>a{
    @extend .overall;
    //pointer-events: none;
  }
  .bg {
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform .5s ease;
  }
  .bg-grad-01 {
    opacity: .9;
  }
  .txt {
    height: 100%;
    position: relative;
    text-align: center;
    z-index: 1;
    //padding-top: 1px;
    padding-bottom: 5px;
  }
  h6.chapter {
    color: #f2c7cc;
    font-size: 10px;
    margin: 0 0 8px;
  }
  h3 {
    font-size: 28px;
    font-weight: bold;
    color: #FFF;
    margin: 0 0 35px;
  }
  .aro {
    display: inline-block;
    margin: 0 auto;
    width: 60px;
    height: 5px;
    background: url('../images/i_layout/aro-next.png');
  }
  a.overall {
    z-index: 2;
  }
  &:hover {
    .bg {
      transform: translate3d(0, 0, 60px);
    }
    .aro {
      animation: out-right-in-left .5s ease;
    }
  }
}*/
/*
a.side-strokes {
  display: inline-block;
  padding: 5px 0;
  text-decoration: none !important;
  text-transform: uppercase;
  color: $brand-color-1;
  letter-spacing: .2em;
  line-height: 2;
  font-size: 10px;
  font-weight: 600;
  //padding-left: 4px;
  transition: all .25s;
  span {
    display: inline-block;
  }
  &::before, &::after {
    content: '';
    box-sizing: content-box;
    display: inline-block;
    vertical-align: top;
    width: 50px;
    height: 1px;
    background: $brand-color-1;
    margin-top: 9px;
    transition: all .25s;
  }
  &::before {
    width: 0;
  }
  &::after {
    margin-left: 10px;
  }
  &:hover {
    color: #000;
    &::before, &::after {
      background-color: #000;
    }
    &::before {
      width: 50px;
      margin-right: 10px;
    }
    &::after {
      width: 0;
      margin-left: 0;
    }
  }
}

.btn-1 {
  font-size: 15px;
  font-weight: bold;
  color: $brand-color-1;
  background: #FFF;
  border: 2px solid #edb7c1;
  height: 56px;
  padding: 6px 48px;
  position: relative;
  z-index: 1;
  box-shadow: 5px 5px 0 rgba(#edb7c1, .5);
  &::before {

  }
}

.btn-side-arrows {
  display: inline-block;
  text-decoration: none !important;
  background: transparent;
  span {
    //display: inline-block;
    vertical-align: middle;
    &::before, &::after {
      content: '';
      display: inline-block;
      position: relative;
      width: 15px;
      height: 9px;
      background: url(../images/i_layout/aro-btn-col1.png) top left no-repeat;

      transition: all .2s ease;
      margin-top: -6px;
      //vertical-align: middle;
    }
    &::before {
      width: 0;
      margin-right: 8px;
      background-position: top right;
    }
    &::after {
      margin-left: 8px;
    }
  }
  &:hover {
    color: #000;
    span {
      &::before, &::after {
        background-image: url(../images/i_layout/aro-btn-000.png);
      }
      &::before {
        width: 15px;
        //margin-right: 8px;
      }
      &::after {
        width: 0;
        //margin-left: 0;
      }
    }
  }
}

.btn-scroll {
  position: absolute;
  width: 40px;
  height: 90px;
  bottom: -20px;
  left: 50%;
  margin-left: -20px;
  background: url('../images/i_layout/aro-scroll.png') top no-repeat;
  cursor: pointer;
}*/

@media screen and (min-width: $screen-sm) {
  /*.btn-scroll {
    //border: 1px solid red;
    &::after {
      content: 'scroll';
      display: block;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      color: #000;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: .2em;
      transition: color .2s ease;
    }
    &:hover {
      &::after {
        color: $brand-color-1;
      }
    }
  }*/
}

@media screen and (min-width: $screen-md) {
  /*header.home{
    min-height: 620px;
  }
  .btn-scroll{
    bottom: 20px;
  }*/
}