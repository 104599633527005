.projects-ul {
  transition: height .3s ease;
  overflow: hidden;
  font-family: $font-family-monospace;
  > div {
    opacity: 0;

    pointer-events: none;
    transition: opacity .3s ease;
    //transform:translateY(10px);
    //width: 0;
    //height: 0;
    &.on {
      //transform:translateY(0);

      pointer-events: auto;
      opacity: 1;
    }
  }
}

.empty-msg {
  height: 0;
  text-align: center;
  width: 100%;
  &.on {
    height: auto;
    padding: 30px;
  }
}

.projects-li {
  display: block;
  position: relative;
  text-align: center;
  margin: 0 0 30px;
  line-height: 1.5;
  perspective: 1000px;
  overflow: hidden;
  height: 0;
  padding-top: 66.667%;
  width: 100%;
  .img {
    position: absolute;
    padding-top: 66.667%;
    width: 100%;
    display: block;
    background: {
      size: cover;
      position: center;
    }
    top: 0;
    left: 0;
  }
  h3 {
    margin: 5px 0 5px;
  }
  .info {
    transform: translateY(6px);
    padding: 0 15px;
    font: {
      size: 14px;
      weight: 300;
    }
    letter-spacing: .06em;
    * {
      //margin: 0;
      color: #FFF;
    }
  }

  .overall, img, .info {
    transition: all .3s ease;
  }
  .overall {
    background: rgba(#555, .8);
    opacity: 0;
  }
  &:hover {
    .img {
      filter: grayscale(1);
    }
    .info {
      transform: translateY(0px);
    }
    .overall {
      opacity: 1;
    }
  }
}

.project-gallery, .project-gallery.slick-dotted.slick-slider {
  margin-bottom: 80px;
  .bg {
    height: 0;
    padding-top: 55%;
    padding-top: 66.6666%;
    background: #f5f5f5 center no-repeat;
    background-size: contain;

  }
}

.dl-project {
  dt {
    //color: #999;
    font-family: $font-family-monospace;
    font-weight: 900;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: .16em;
    font-size: 70%;
  }
}

.blueprints {
  margin-top: 80px;
  margin-bottom: 80px;
  a {
    padding: 10px;
  }
  .bg {
    padding-top: 75%;
    background: {
      size: contain;
      repeat: no-repeat;
      position: center;
      color: #f5f5f5;
    }
  }
}

//@media (max-width: $screen-xs-max) {

@media (min-width: $screen-sm-min) {
  .dl-project {
    dt {
      display: flex;
      //line-height: 40px;
      padding-top: 8px;
      //vertical-align: bottom;
      &::after {
        content: '';
        display: inline-block;
        flex: 1;
        border-bottom: 1px solid #DDD;
        margin: 0 0 9px 10px;
      }
    }
  }
}

html.touch {

  .projects-li {
    overflow: visible;
    height: auto;
    padding-top: 0;
    text-align: left;
    margin-bottom: 20px;
    > .img {
      position: relative;
      margin-bottom: 10px;
    }
    .overall {
      position: relative;
      background: none;
      opacity: 1;
    }
    .middle-v {
      display: block;
    }
    .info {
      overflow: hidden;
      transform: none;
      padding: 0;
      min-height: 84px;
      font: {
        size: 12px;
        weight: 400;
      }
      * {
        color: #000;
      }
      h3 {
        letter-spacing: 0;
        font: {
          size: 16px;
        }
      }
    }
    &:hover, &:focus {
      text-decoration: none;
      .img {
        filter: none;
      }
    }
  }
  @media (max-width: $screen-xs-max) {
    .projects-ul {
      > div {
        width: 100%;
      }
    }
    .projects-li {
      > .img {
        width: 30%;
        padding-top: 20%;
        float: left;
        margin-top: 8px;
      }
      .info {
        padding-left: 15px;
      }
    }
  }
}