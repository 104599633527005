header.main {
  .navbar-brand {
    text-transform: uppercase;
    letter-spacing: .14em;
    line-height: 12px;
    padding: 15px 0 10px;
    height: auto;
    font: {
      family: $font-family-monospace;
      size: 10px;
      weight: 500;
    }
    span.initial {
      border-top: 1px solid rgba(#000, .5);
      display: inline-block;
      line-height: 1.6;
      font: {
        weight: 700;
        //size: 140%;
      }

    }
  }
}

@media (min-width: $screen-sm-min) {
  header.main {
    padding-top: 20px;
    .navbar-brand {
      padding: 8px 0;
      margin-top: 11px;
      span.initial {
        border-top:none;
        border-bottom: 1px solid rgba(#000, .5);

      }
    }
  }
}
