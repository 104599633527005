ul.awards {
  list-style: none;
  padding: 0;
  line-height: 1.6;
  li {
    padding-left: 30px;
    position: relative;
    margin-bottom: 30px;
    font-size: 14px;
    &::before {
      content: '\f219';
      font-family: FontAwesome;
      font-size: 14px;
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 0px;
    }

    .title {
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: .2em;
      .date {
        color: #777;
        font-size: 14px;
        letter-spacing: 0px;
        &::before {
          content: '—';
          display: inline;
          margin: 0 5px;
        }
      }
    }
  }
}

.pic-awards {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 35%;
  margin-bottom: 50px;
}

@media (min-width: $screen-md) {
  .pic-awards {
    padding-top: 67%;
    //background-size: contain;
    //background-position: top;

  }
}