.bg-01 {
  background-color: $brand-color-1;
}
.bg-04 {
  background-color: $brand-color-4;
}

.bg-grad-01 {
  background: linear-gradient(120deg, $brand-color-1 30%, $brand-color-2 70%);
}

.bg-grad-02 {
  background: linear-gradient(120deg, $brand-color-1 10%, $brand-color-2 90%);
}
.bg-grad-02-85 {
  background: linear-gradient(120deg, rgba($brand-color-1,.85) 10%, rgba($brand-color-2,.85) 90%);
}
.bg-bi-00{
  background: linear-gradient(to right, transparent 50%, #FFF 50%);
}
.bg-bi-01, .bg-bi-01-flip {
  background: $brand-color-4;
}

.bg-gallery{
  background: $brand-color-4;
  padding-top: 1px;
  //box-shadow: 0 80px 0 #FFF inset;
}

.bg-bi-01-md,.bg-bi-01-sm {
  background: #FFF;
}

.bg-notebook {
  //background-image: linear-gradient(to right, #ededed 0px, #ededed 1px, #FFF 1px, #FFF 179px);
  //background-size: 179px 1px;
  //background-repeat: repeat;
  //background-position: top;
  background: #FFF url(../images/i_layout/bg-notebook-xs.png) top;
}
.bg-img{
  background-size: cover;
  background-position: center;
}
.bg-pic {
  background-size: cover;
  background-position: center;
  padding-top: 66%;
  &-native {
    background-size: auto;
  }
}

.bg-text {
  position: relative;
  &::before {
    content: attr(data-text-bg);
    position: absolute;
    left:50%;
    transform:translate(-50%, -20px);
    font-size: 74px;
    font-weight: 800;
    letter-spacing: .06em;
    color: #eceded;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .nobg-xs{
    background: transparent;
  }
  
}
@media screen and (min-width: $screen-sm) {
  .bg-pic {
    padding-top: 62.5%;
  }
  .bg-bi-01-sm {
    background: linear-gradient(to right, $brand-color-4 50%, #FFF 50%);
  }
  .bg-notebook {
    //background-image: linear-gradient(to right, #ededed 0px, #ededed 1px, #FFF 1px, #FFF 341px);
    //background-size: 341px 1px;
    //background-repeat: repeat;
    //background-position: top;
    background: #FFF url(../images/i_layout/bg-notebook-sm.png) top;

  }
}

@media screen and (min-width: $screen-md) {
  /*.bg-notebook {
    background-image: linear-gradient(to right, #ededed 0px, #ededed 1px, #FFF 1px, #FFF 301px);
    background-size: 301px 1px;
  }*/
  .bg-bi-01 {
    background: linear-gradient(to right, $brand-color-4 50%, #FFF 50%);
  }
  .bg-bi-01-flip {
    background: linear-gradient(to right, #FFF 50%, $brand-color-4 50%);
  }
  .bg-bi-01-md {
    background: linear-gradient(to right, $brand-color-4 50%, #FFF 50%);
  }
  .bg-pic {
    padding-top: 40%;
  }
  .bg-gmap{
    background-color: $brand-color-4;
    background-image: linear-gradient(to right, transparent 50%, #FFF 50%);
    background-size: 100% 80px;
    background-position: top;
    background-repeat: no-repeat;
  }
}
@media screen and (min-width: $screen-lg) {
  .bg-notebook {
    //background-image: linear-gradient(to right, #ededed 0px, #ededed 1px, #FFF 1px, #FFF 301px);
    //background-size: 301px 1px;
    background: #FFF url(../images/i_layout/bg-notebook-lg.png) top;

  }
}