$icon-font-path: "fonts/bootstrap/";

//@import "../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap";
//@import url('http://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900,900italic,700italic,500italic,400italic,300italic,100italic&subset=latin,cyrillic');

//@import url('http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,300,400,600,700,800');
//@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,600,700,700italic,400italic);
//@import 'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700';
//@import 'https://fonts.googleapis.com/css?family=Oswald:300,400,700';
//@import url('http://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');
//@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic,900italic,900);
//@import url(https://fonts.googleapis.com/css?family=Lora:400,400italic,700,700italic);
//@import url(http://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700);
//@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:100,300,400,500,700');
//@import "../../bower_components/lightGallery/src/css/lightgallery.css";

@import "../styles/fonts/fontawesome/css/font-awesome.css";
@import "../styles/fonts/elusive/css/elusive-icons.css";

//@import "../styles/fonts/renicons/renicon.css";

// SLICK SLIDER
//$slick-font-path: "./fonts/slick/";
//@import "../../bower_components/slick-carousel/slick/slick-theme";
//@import "../../bower_components/slick-carousel/slick/slick";

//@import "partials/colors";
//@import "bootstrap/yeti/variables";
@import "mixins/hsv";
@import "partials/vars";
@import "bootstrap/bootstrap";
@import "partials/xl-grid";
//@import "bootstrap/yeti/bootswatch";

@import "partials/easing";
//@import "partials/jquery.lightbox";
@import "partials/base";
//@import "partials/breadcrumb";
//@import "partials/carousel";
@import "partials/header";
@import "partials/nav";
@import "partials/images";
@import "partials/type";
//@import "partials/lists";
@import "partials/pagination";
@import "partials/form";
@import "partials/selectFX";
//@import "partials/basket";
//@import "partials/gallery";
@import "partials/blocks";
@import "partials/projects";
//@import "partials/tables";
@import "partials/buttons";
@import "partials/alerts";
@import "partials/scroll-nav";

//@import "partials/search";
@import "partials/home";
@import "partials/team";
@import "partials/awards";
@import "partials/publications";
//@import "partials/schedule";
//@import "partials/register";
//@import "partials/practical-info";
@import "partials/grid";
@import "partials/bgs";
@import "partials/404";
@import "partials/animations";
@import "partials/filter-sort";
//@import "partials/map";
//@import "partials/works";
@import "partials/news";
//@import "partials/calendar";
@import "partials/contact";
@import "partials/footer";
//@import "partials/staticElements";
//@import "partials/tiny";
//@import "partials/bigvideo";
@import "partials/btn-bg-zoom";
//@import "partials/spa";
//@import "partials/gallery";
//@import "partials/marketing";
//@import "partials/svg";
//@import "lightslider/lightslider";
@import "lightgallery/main";
//@import "partials/mockup";

@import "partials/slider";
