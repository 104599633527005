.team-li {
  line-height: 1.6em;
  margin-bottom: 50px;
  font-size: 14px;
  .name {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0;
  }
  p {
    margin: 0;
  }
  a.email {
    line-height: 1.4em;
    .icon {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      //overflow-x: hidden;
      text-align: left;
      //transition: all .2s ease;
      //background: #000;
      /*&::before{
        content:'\f2b7';
        display: inline-block;
        vertical-align: middle;
        line-height: 1em;
        font-family: FontAwesome;
      }*/

    }
    .fa {
      //margin-left: 10px;
      //transition: all .2s ease;
      transform: translateY(-2px);
    }
    &:hover {
      .icon {
        width: 22px;
      }
      .fa {
        margin-left: 1px;

      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .team-li {
    .name {
      margin-top: 20px;
    }
    a.email {
      .icon {
        width: 0;
        overflow-x: hidden;
        transition: all .2s ease;
      }
      .fa {
        margin-left: 10px;
        transition: all .2s ease;
        //transform: translateY(-2px);
      }
      &:hover {
        .icon {
          width: 22px;
        }
        .fa {
          margin-left: 1px;
        }
      }
    }
  }
}