/* Default custom select styles */
.has-error {
  div.cs-select {
    > span, .cs-options {
      border-color: $brand-color-1;
    }
  }
}

div.cs-select {
  //display: inline-block;
  background: #EEE;
  font: {
    weight: normal;
    size: 13px;
  }

  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  z-index: 100;
  width: 100%;
  //max-width: 500px;
  -webkit-touch-callout: none;
  user-select: none;
  &:focus {
    outline: none;
    /* For better accessibility add a style for this in your skin */
  }
}

.cs-select {
  select {
    display: none;
  }
  span {
    display: block;
    position: relative;
    cursor: pointer;
    //padding: 1em;
    padding: 6px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > span {
    cursor: default;
    font-family: $font-family-monospace;
    //font-style: italic;
    border: 1px solid $input-border;
    padding-right: 3em;
    &::after {
      content: '+';
      font: {
        style: normal;
        //family: FontAwesome;
      }
      color:#BBB;
      //line-height: 0;
      right: 1em;
      position: absolute;
      //margin-top: -2px;
      //width: 10px;
      //height: 8px;
      //top: 50%;
      //transform: translateY(-50%);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  .cs-selected {
    span {
      color: #000;
      background: #f5f5f5;
      /*&::after {
        speak: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-smoothing: antialiased;
        content: '\2713';
        margin-left: 1em;
      }*/
    }
  }

  &.cs-active {
    > span {
      //border-bottom: none;
      &::after {
        //transform: translateY(-50%) rotate(180deg);
        content:'-';
      }
    }
  }

  /* Options */
  .cs-options {
    position: absolute;
    overflow: hidden;
    width: 100%;
    background: #fff;
    visibility: hidden;
    border: 1px solid $input-border;
    border-top: none;
    margin-top: -1px;
    transform-origin: 0 0;
    //transform: scaleY(0);
    transform: translateY(-12px);
    opacity: 0;
    transition: transform .2s ease, opacity .2s ease;

  }
  &.cs-active .cs-options {
    visibility: visible;
    //transform: scaleY(1);
    transform: translateY(0);
    opacity: 1;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    span {
      //padding: 1em;
    }
    li {
      &.cs-focus span {
        background-color: #ddd;
      }
      &:hover {
        background: $brand-color-1;
        color: #FFF;
      }
    }
  }
  li.cs-optgroup {
    ul {
      padding-left: 1em;
    }
    > span {
      cursor: default;
    }
  }
}

/* Placeholder and selected option */

div.cs-active {
  z-index: 200;
}

/* filter nav*/
.cs-select.filter-nav {
  margin: 35px 0 16px;
  span {
    //padding: 14px 12px;

  }
  text-transform: uppercase;
  letter-spacing: .2em;
  font-weight: 600;
  font-size: 12px;
  span {
    padding: 20px 18px;
    line-height: 1.8;
  }
  .cs-placeholder {
    font-family: $font-family-base !important;
    font-style: normal;
    padding-right: 3em;
  }
  .cs-options {
    span {
      border-top: 1px solid #e3e3e3;
      white-space: normal;
      text-overflow: clip;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .cs-select.filter-nav {
    margin: 27px 0 49px 7px;
    width: 280px;
  }
}