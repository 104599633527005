.pic-bg-3by2{
  padding-top: 66.6666%;
  background:{
    color:#f5f5f5;
    position: center;
    size:contain;
    repeat:no-repeat;
  }
}
.frame {
  position: relative;
  display: inline-block;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    box-shadow: 0 0 0 2px #f9e4e6 inset;
    width: 100%;
    height: 100%;
    transform: translate(10px, 10px);
    z-index: -1;
  }
}

.pic-caption {
  position: relative;
  padding-bottom: 49px;
  .pic {
    position: relative;
  }
  .caption {
    display: none;
    position: relative;
    transform: translateX(-217px) rotate(-90deg);
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid $brand-color-1;
    padding: 43px 34px 52px;
    //color: #000;
    ul{
      padding: 0;
      margin: 0;
      list-style: none;
      li{
        display: inline-block;
        color: #707070;
        margin: 0 11px;
        letter-spacing: .2em;
      }
    }
    span {
      color: #707070;
      margin: 0 11px;
      letter-spacing: .2em;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .pic-caption {
    padding-bottom: 70px;
    .pic {
      width: 75%;
      float: right;
      padding-left: 5px;
    }
    .caption {
      display: inline-block;
    }
  }
}
