@import "../slick/main";

[data-gfx="slick"] {
  padding: 0;
  img {
    width: 100%;
  }
}

.slick-slider {
  &.slick-dotted {
    //margin-bottom: 0;
  }
  .slick-slide {
    position: relative;
    .caption {
      position: absolute;
      top: 50%;
      left: 6%;
      right: 6%;
      transform: translateY(-35%);
      transition: all .5s ease .5s;
      opacity: 0;
      padding: 0 15px 30px;
      > * {
        //max-width: 75%;

      }
    }
    &.slick-current {
      .caption {
        transform: translateY(-50%);
        opacity: 1;
      }
    }
  }
}

.slick-arrow {
  //text-shadow: 0 0 3px rgba(#000, .5), 0 0 10px rgba(#000, .3);
  text-shadow: 0 0 3px #f5f5f5, 0 0 3px #f5f5f5, 0 0 3px #f5f5f5, 0 0 3px #f5f5f5, 0 0 3px #f5f5f5, 0 0 10px #f5f5f5;
}

.slick-dots {
  bottom: -30px;
  li {
    margin: 0;
    width: 30px;
    position: relative;
    button {
      width: 100%;
      &:before {
        //color: #FFF;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 4px;
        background: #CCC;
        opacity: .5;
        font-size: 6px;
        transition: all .4s ease;
      }
    }
    &.slick-active {
      button {
        &:before {
          //color: #FFF;
          background: #000;
          height: 8px;
          opacity: 1;
          //text-shadow: 0 0 5px rgba(#000, .5);
        }
      }
    }

  }
}

.slick-arrow {
  width: 60px;
  height: 60px;
  //background: $brand-color-1;
  //background: #FFF;
  //border: 1px solid $panel-default-border;
  //color: $panel-default-border;
  color: #444;
  z-index: 1;
  &:before {
    font-size: 36px;
    font-family: FontAwesome;
    color: inherit;
    //text-shadow: 0 0 10px rgba(#000,0.3), 0 0 60px rgba(#000,0.8);
  }
  &:hover, &:focus {
    //background: $brand-color-1;
    //border-color: $brand-color-1;
    color: #000;
  }
}

.slick-prev {
  left: 0;
  //border-radius: 0 3px 3px 0;

  &:before {
    content: '\f104';
  }
}

.slick-next {
  right: 0;
  //border-radius: 3px 0 0 3px;
  &:before {
    content: '\f105';
  }
}