body.pid_012 {
  //overflow: hidden;
  //background: none;
  position: relative;
  * {
    color: #FFF !important;
  }
  .navbar-toggle {
    .icon-bar {
      background: #FFF;
    }
  }
  .navbar-collapse {
    border-width: 0 !important;
  }
  .navbar-nav {
    background: rgba(#000, .6);
  }
  header.main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    .navbar-brand {
      span {
        border-color: rgba(#FFF, .5);
      }
    }

  }
  footer.main {
    position: absolute;
    height: auto;
    padding: 0;
    bottom: 15px;
    left: 0;
    right:0;
    //display: none;
    z-index: 999;
    .by-nec{
      color: #FFF;
    }
  }
  .navbar-nav {
    li {
      &::after {
        background: #FFF;
      }
    }
  }
  .home-quote {
    color: #FFF;
    //text-shadow: 0 0 10px rgba(#000, .5),0 0 20px rgba(#000, 1),0 0 40px rgba(#000, 1), 0 0 80px #000, 0 0 200px #000;
    background: rgba(#000, .25);
    padding: 0 30px;
    blockquote {
      display: block;
      text-transform: uppercase;
      font: {
        weight: 300;
        //size: 48px;
      }
    }
  }
  .slider {
    .slide {
      perspective: 1000px;
      position: relative;
      .bg {
        filter: saturate(.4);
        //filter: grayscale(.5);
        transform: translate3d(0, 0, 50px);
        transition: transform 7s linear;
      }
      &.on {
        .bg {
          //animation: zoom-out 7s linear;
          transform: translate3d(0, 0, 0px);
        }
      }
    }

  }
}

@media (min-width: $screen-sm) {
  body.pid_012 {
    .navbar-nav {
      background: transparent;
    }
    .home-quote {
      blockquote {
        //max-width: 50%;
        margin: 0 20%;
        font: {
          size: 36px;
          weight: 100;
        }
        cite {
          font-weight: 300;
        }
      }
    }
  }
}