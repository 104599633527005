.bg-cover {
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  &:hover {
    text-decoration: none;
  }
}

.gallery {
  a {
    perspective: 500px;
    overflow: hidden;
    .bg-cover{
      transform: translate3D(0, 0, 0);
      transition: 0.5s;

    }
    .overall {
      background: rgba(#3e3f39, .7);
      opacity: 0;
      transition: 0.5s;
      transform: translate3D(0, 0, 120px);
      &::before {
        content: '\f00e';
        font-family: FontAwesome;
        color: #FFF;
        font-size: 3vmin;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &:hover {
      .bg-cover{
        transform: translate3D(0, 0, 50px);

      }
      .overall {
        opacity: 1;
        transform: translate3D(0, 0, 0);

      }
    }
  }
}

.third-screen {
  display: block;
  position: relative;
  float: left;
  width: 33.333333%;
  height: 33.333333%;
}

.btns-half {
  display: inline;
  //position: static;
  //font-size: 0;
  > * {
    width: 100%;
    height: 50%;
    display: block;
    float: left;
    position: relative;
    overflow: hidden;
  }
}

a.btn-bg-zoom {
  font-size: 14px;
  margin: 0;
  color: #FFF;
  > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .bg {
    background-position: center;
    background-size: cover;
    transform: perspective(500px) translateZ(0);
    transition: all 2s linear;
  }
  .txt {
    font-family: $headings-font-family;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0 5%;
    transition: all 0.5s;

    .title {
      display: block;
      vertical-align: baseline;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 5vmin;
      //margin-bottom: 25px;

    }
    .desc {
      font-size: 2.8vmin;
      font-weight: 100;
      vertical-align: baseline;
      &::before {
        background-color: $brand-color-2;
        content: '';
        display: block;
        height: 4px;
        width: 8vmax;
        margin: 2.3125vmin auto;
      }
    }
  }
  .hover {
    opacity: 0;
    background: rgba($brand-color-1, .8);
    transition: 0.5s;
  }


  &:hover {
    .bg {
      transform: perspective(500px) translateZ(25px);
    }
    .hover {
      opacity: 1;
    }
    .txt {
      //transform: translateY(-50%);

    }
  }
}

@media screen and (min-width: $screen-sm) {
  .btns-half {

    > * {
      width: 50%;
      height: 50%;
    }
  }

}