
@keyframes out-right-in-left {
  0% {
    //transform: translateX(0);
    //opacity: 1;
  }
  40% {
    transform: translateX(80%);
    opacity: 0;
  }
  41% {
    transform: translateX(-80%);
    //opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes out-left-in-right {
  0% {
    //transform: translateX(0);
    //opacity: 1;
  }
  40% {
    transform: translateX(-80%);
    opacity: 0;
  }
  41% {
    transform: translateX(80%);
    //opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes join-in {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes zoom-out {
  0% {
    transform: translate3d(0,0,50px);
  }
  100% {
    transform: translate3d(0,0,0px);
  }
}