.publications-li{
  padding: 20px 15px 15px;
  border: 1px solid #e8e8e8;
  background: #FFF;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: $font-family-monospace;
  .date{
    font-size: 13px;
    color: #999;
    margin-bottom: 5px;
  }
  .name{
    font-weight: bold;
    margin-bottom: 20px;
  }
}