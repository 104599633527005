
* {
  outline: none !important;
}


img {
  max-width: 100%;
  height: auto;
}
.intro{
  color: $brand-color-1;
  font-size: 18px;
  margin: 0 0 30px;
}
.no-scroll {
  overflow: hidden;
}

.gmap {
  //position: absolute;
  //width: 100%;
  //height: 100%;
  line-height: 1.6;
  height: 600px;
  max-height: 60vh;
  img {
    max-width: none;
  }
  h2, h3, h4 {
    margin-top: 5px;
  }
  p {
    margin: 8px 0;
    font-weight: normal;
  }
}

.full-screen{
  width: 100vw;
  height: 100vh;
  &.bg{
    background-position: center;
    background-size: cover;
  }
}

.map-overlay {
  position: absolute;
  bottom: 0;

  background: rgba(#3e3f39, .85);
  padding: 30px;
  h2 {
    font-weight: 100;
    text-transform: uppercase;
    margin: 0 0 10px;
  }

}

main {
  z-index: 1;
}

section.main {
  margin: 0;
  padding: 10px 15px 30px;

}
section.bg-1 {
  background: $brand-color-1;
}
section.bg-2 {
  background: $brand-color-2;
}
section.bg-2-paler {
  background: change_hsb($brand-color-2,$b:95%,$s:50%);
}
section.bg-3 {
  background: $brand-color-3;
}
section.bg-4 {
  color: #FFF;
  background: $brand-color-4;
}

.padTop {
  padding-top: 180px;
}

.padV {
  padding-top: 60px;
  padding-bottom: 60px;
}

section.paper {
  background: #fff;
  color: #444;
  &.work-item {
    padding: 100px 0;
    min-height: 100%;
  }
}

ul.check {
  list-style: none;
  padding: 0;
  > li {
    &::before {
      content: '\f00c';
      font-family: FontAwesome;
      margin-right: 5px;
      font-size: 14px;
      //color: #9bc66d;
    }
  }
}


.overall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  &:hover, &:focus {
    text-decoration: none;
  }
}



.accordion {
  .panel-body {
    border-top: 1px solid $panel-default-border;
  }
  .drawer {
    .toggler {
      cursor: default;
      position: relative;
      padding-right: 28px;
      &::after {
        content: '\f0d7';
        font-family: FontAwesome;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        background: adjust_hsb($panel-default-heading-bg, $b: -1%, $s: 3%);
        text-shadow: 0 1px 0 #FFF;
      }
    }
    &.on {
      .toggler {
        &::after {
          content: '\f0d8';
        }
      }
    }
  }
}

@media (min-width: $screen-sm) {
  section.main {
    padding: 20px 30px 30px;

  }
  .map-overlay {
    width: 33%;
    top: 50px;
    right: 50px;
    bottom: auto;
  }
}
@media (min-width: $screen-md) {
  .gmap{
    max-height: none;
  }
  .pushed{
    margin-bottom: 100px;
  }
}