/*
@function hsb($h-hsb, $s-hsb, $b-hsb, $a: 1) {
  @if $b-hsb == 0 {
    @return hsla(0, 0, 0, $a)
  } @else {
    $l-hsl: ($b-hsb/2) * (2 - ($s-hsb/100));
    $s-hsl: ($b-hsb * $s-hsb) / if($l-hsl < 50, $l-hsl * 2, 200 - $l-hsl * 2);
    @return hsla($h-hsb, $s-hsl, $l-hsl, $a);
  }
}
*/

@function max($v1, $v2) {
  @return if($v1 > $v2, $v1, $v2);
}
@function min($v1, $v2) {
  @return if($v1 < $v2, $v1, $v2);
}

@function hsv_to_hsl($h, $s: 0, $v: 0) {
  @if type_of($h) == 'list' {
    $v: nth($h, 3);
    $s: nth($h, 2);
    $h: nth($h, 1);
  }

  @if unit($h) == 'deg' {
    $h: 3.1415 * 2 * ($h / 360deg);
  }
  @if unit($s) == '%' {
    $s: 0 + ($s / 100%);
  }
  @if unit($v) == '%' {
    $v: 0 + ($v / 100%);
  }

  $ss: $s * $v;
  $ll: (2 - $s) * $v;

  @if $ll <= 1 {
    $ss: $ss / $ll;
  } @else if ($ll == 2) {
    $ss: 0;
  } @else {
    $ss: $ss / (2 - $ll);
  }

  $ll: $ll / 2;

  @return 360deg * $h / (3.1415 * 2), percentage(max(0, min(1, $ss))), percentage(max(0, min(1, $ll)));
}

@function percent_to_float($num){
  @if unit($num) == '%' {
    $num: 0 + ($num / 100%);
  }
  @return $num;
}

@function float_to_percent($num){
  @if unit($num) != '%' {
    $num: percentage($num);
  }
  @return $num;
}

@function deg_to_rad($num){
  @if unit($num) == 'deg' {
    $num: 3.1415 * 2 * ($num / 360deg);
  }
  @return $num;
}

@function hsl_to_hsv($h, $ss: 0, $ll: 0) {
  @if type_of($h) == 'list' {
    $ll: nth($h, 3);
    $ss: nth($h, 2);
    $h: nth($h, 1);
  } @else if type_of($h) == 'color' {
    $ll: lightness($h);
    $ss: saturation($h);
    $h: hue($h);
  }

  @if unit($h) == 'deg' {
    $h: 3.1415 * 2 * ($h / 360deg);
  }
  @if unit($ss) == '%' {
    $ss: 0 + ($ss / 100%);
  }
  @if unit($ll) == '%' {
    $ll: 0 + ($ll / 100%);
  }

  $ll: $ll * 2;

  @if $ll <= 1 {
    $ss: $ss * $ll;
  } @else {
    $ss: $ss * (2 - $ll);
  }

  $v: ($ll + $ss) / 2;
  $s: (2 * $ss) / ($ll + $ss);

  @return 360deg * $h / (3.1415 * 2), percentage(max(0, min(1, $s))), percentage(max(0, min(1, $v)));
}

@function color_to_hsv($color) {
  @return hsl_to_hsv($color);
}

@function hsv($h, $s: 1, $v: 1, $a: 1) {
  @if $v == 0 {
    @return hsla(0,0,0,$a);
  }
  $hsl: hsv_to_hsl($h, $s, $v);
  @return hsla(nth($hsl, 1), nth($hsl, 2), nth($hsl, 3), $a);
}
@function hsb($h, $s: 1, $b: 1, $a: 1) {
  @return hsv($h, $s, $b, $a);
}

@function change_hsv($color, $h:null, $s:null, $v:null, $a:1){
  $tmpHsv : color_to_hsv($color);
  $newH : if($h != null, $h, nth($tmpHsv, 1));
  $newS : if($s != null, $s, nth($tmpHsv, 2));
  $newV : if($v != null, $v, nth($tmpHsv, 3));
  @return hsv($newH, $newS, $newV,$a);
}
@function change_hsb($color, $h:null, $s:null, $b:null, $a:1){
  @return change_hsv($color, $h, $s, $b, $a);
}


@function adjust_hsv($color, $h:0, $s:0, $v:0, $a:1){
  $tmpHsv : color_to_hsv($color);
  $newH : nth($tmpHsv, 1) + deg_to_rad($h);
  $newS : nth($tmpHsv, 2) + float_to_percent($s);
  $newV : nth($tmpHsv, 3) + float_to_percent($v);
  @return hsv($newH, $newS, $newV,$a);
}
@function adjust_hsb($color, $h:0, $s:0, $b:0, $a:1){
  @return adjust_hsv($color, $h, $s, $b, $a);
}