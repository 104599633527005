.news-ul {
  font:{
    //family: $font-family-monospace;
  }
  .year-sep {
    //font-weight: 900;
    overflow: hidden;
    font-size: 16px;
    letter-spacing: .4em;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-bottom: 54px;
    font-family: $font-family-monospace;
    //margin-left: ($grid-gutter-width*.5);
    //margin-right: ($grid-gutter-width*.5);
    //float: none;
    //clear: both;
    &::before, &::after {
      content: '';
      display: inline-block;
      //vertical-align: middle;
      margin-bottom: 8px;
      flex: 1;
      border-bottom: 1px solid #000;
    }
    &::before {
      margin-right: 20px;
    }
    &::after {
      margin-left: 20px;
    }
  }
}
.news-li {
  display: block;
  text-decoration: none !important;
  margin-bottom: 40px;
  transition: box-shadow .3s ease;
  box-shadow: 0 0 0 10px #FFF, 0 0 0 11px #FFF;
  line-height: 1.2;
  .pic {
    overflow: hidden;
    padding-top: 66%;
    perspective: 1000px;
    margin: 4px 0 16px;
  }
  .bg-img {
    transition: transform .3s ease;
  }
  .info {
    overflow: hidden;
  }
  .title {
    //font-weight: bold;
    margin-bottom: 10px;
  }
  .date {
    color: #999;
    font-size: 12px;
    letter-spacing: .16em;
  }
  &:hover {
    //box-shadow: 0 0 0 10px #FFF, 0 0 0 11px #DDD;
    .bg-img {
      transform: translate3d(0, 0, 30px);
    }
  }
}
.news-item{
  h1{
    margin-bottom: 10px;
    //letter-spacing: 0em;
    //text-transform: none;
  }
  .date{
    color: #999;
    font-size: 14px;
    letter-spacing: .16em;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: $screen-sm) {
  .news-li {
    .pic {
      padding-top: 50%;
      margin: 0 0 16px;
    }
  }
  .news-year > div{
    &:nth-child(2n+1){
      clear: left;
    }
  }
}
@media screen and (min-width: $screen-md) {
  .news-year > div{
    &:nth-child(2n+1){
      clear: none;
    }
    &:nth-child(3n+1){
      clear: left;
    }
  }
}
@media screen and (min-width: $screen-xl) {
  .news-year > div{
    &:nth-child(3n+1){
      clear: none;
    }
    &:nth-child(4n+1){
      clear: left;
    }
  }
}
