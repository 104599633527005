.contact-map{
  margin: 0 0 60px;
}
.contact-people{
  margin: 0 0 80px;
  ul{
    list-style: none;
    padding: 0;
    li{
      padding-left: 24px;
      position: relative;
      &::before{
        content: '\f21b';
        font-family: Elusive-Icons;
        font-size: 14px;
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0px;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .contact-map{
    h3{
      margin-top: 0;
    }
  }
}
@media (min-width: $screen-sm) {
  .contact-map{
    margin: 80px 0 160px;
  }
}