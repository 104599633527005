.pad-t {
  padding-top: 70px;
}

.pad-b {
  padding-bottom: 30px;
}

.pad-v {
  padding-top: 50px;
  padding-bottom: 40px;
}

.full-h {
  height: 100%;
  min-height: 100%;
}

.middle-v {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gmap {
  border: 1px solid #DDD;

}

.map-info {
  .fa{
    font-size: 14px;
    margin-right: 3px;
  }
}

@media screen and (max-width: $screen-xs-max) {

}

@media screen and (min-width: $screen-sm) {

}

@media screen and (min-width: $screen-md) {
  .map-info {
    position: relative;
    padding: 20px 40px;
    z-index: 300;
    margin: 80px -70px 0 0;
    background: #FFF;
    border: 1px solid #DDD;

  }
}

@media screen and (min-width: $screen-lg) {

}